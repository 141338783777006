<script setup>
import Navigation from '@/Components/Navigation/Index.vue'
import { onMounted } from 'vue'
import Footer from '@/Components/Footer.vue'

if (typeof window !== 'undefined') {
    const query = window.location.search.split('&')
    const identifier = query.find(el => el.includes('id'))
    onMounted(() => document.body.addEventListener('change', goToTarget()))

    function goToTarget () {
        if (identifier) {
            const value = identifier.split('=')[1]
            const targetEl = '#' + value
            const el = document.querySelector(targetEl)
            setTimeout(() => {
                el.scrollIntoView()
            }, 200)
        }
    }
}

const props = defineProps({
    showFooter: {
        type: Boolean,
        default: true
    },
    showLearnMore: {
        type: Boolean,
        default: true
    }
})
</script>

<template>
    <Navigation />
    <aos-vue animation="fade-in">
        <slot />
    </aos-vue>
    <Footer v-if="props.showFooter"/>
</template>
<style>
#nprogress .bar {
    background-image: linear-gradient(to right, white , red)!important;
    height: 4.1rem!important;
    border-radius: 0 2px 2px 0;
    opacity: 10%;
    .peg {
        display: none!important;
    }
}
@media screen and (min-width: 1023px) {
    #nprogress .bar {
        height: 4.5rem!important;
    }
}
</style>
